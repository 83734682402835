/*make sure to import all the other css files here for them to work in the project*/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins.ttf");
}
@font-face {
  font-family: "DM-Sans-Bold";
  src: url("../fonts/Poppins/DMSans-Bold.ttf");
}
@font-face {
  font-family: "DM-Sans-Medium";
  src: url("../fonts/Poppins/DMSans-Medium.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/Inter/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/Inter/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../fonts/Inter/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("../fonts/Inter/Inter-Thin.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../fonts/Inter/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Black";
  src: url("../fonts/Inter/Inter-Black.ttf");
}
@font-face {
  font-family: "Prompt-Medium";
  src: url("../fonts/Poppins/Prompt-Medium.ttf");
}

.min-width-10px {
  min-width: 10px;
}

.max-width-400px {
  max-width: 400px;
}

.position-right-5vw {
  right: 5vw;
}

.max-width-243 {
  max-width: 243px;
}

.min-h-section {
  min-height: calc(100vh - 60px);
}

.markdown table {
  width: 100%;
  border-collapse: collapse;
}

.markdown th, 
.markdown td {
  border: 1px solid #ccc;
  padding: 7px;
  text-align: left;

}

.markdown th:nth-child(1),
.markdown td:nth-child(1) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(2),
.markdown td:nth-child(2) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(3),
.markdown td:nth-child(3) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(4),
.markdown td:nth-child(4) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}

.markdown th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.markdown tbody {
  display: block;
  overflow: auto; 
}

.markdown tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Prevents column resizing issues */
}


.h-section {
  height: calc(100vh - 60px);
}

.h-iframe-section {
  height: calc(100vh - 115px);
}

.h-flow-section {
  height: calc(100vh - 30px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.remove-prefill-autofill input:-webkit-autofill,
.remove-prefill-autofill input:-webkit-autofill:hover,
.remove-prefill-autofill input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}

.gradient-background-base {
  background: linear-gradient(
    221.75deg,
    rgba(52, 231, 242, 0.1) -5.6%,
    rgba(169, 203, 255, 0.12) 24.42%,
    rgba(238, 250, 249, 0) 61.42%
  );
}
.gradient-background-chatbot {
  background: linear-gradient(
    214.34deg,
    rgba(218, 211, 255, 0.33) -3.06%,
    rgba(255, 222, 238, 0.4) 18.34%,
    rgba(255, 255, 255, 0) 51.41%
  );
}
.gradient-background-deploy {
  background: linear-gradient(
    217.21deg,
    rgba(255, 239, 98, 0.23) 0.6%,
    rgba(249, 147, 112, 0.08) 19.99%,
    rgba(238, 250, 249, 0) 46.88%
  );
}
.pricing-background {
  background: var(--23, linear-gradient(141deg, #1A55F9 0%, #ace2f4 100%));
}
.markdown table {
  width: 100%;
  border-collapse: collapse;
}

.markdown th, 
.markdown td {
  border: 1px solid #ccc;
  padding: 7px;
  text-align: left;

}

.markdown th:nth-child(1),
.markdown td:nth-child(1) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(2),
.markdown td:nth-child(2) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(3),
.markdown td:nth-child(3) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(4),
.markdown td:nth-child(4) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(5),
.markdown td:nth-child(5) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(6),
.markdown td:nth-child(6) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(7),
.markdown td:nth-child(7) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(8),
.markdown td:nth-child(8) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(9),
.markdown td:nth-child(9) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(10),
.markdown td:nth-child(10) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(11),
.markdown td:nth-child(11) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}
.markdown th:nth-child(12),
.markdown td:nth-child(12) {
  max-width: 300px;  /* Adjust this as needed */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds '...' for overflowed content */
  /* white-space: nowrap; */
  white-space: normal; /* Allows text to wrap properly */
  word-wrap: break-word;
}

.markdown th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.markdown tbody {
  display: block;
  overflow: auto; 
}

.markdown tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Prevents column resizing issues */
}


input[type="checkbox"]:checked {
  background: #1A55F9;
  color: white;
  border-radius: 2px;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white;
  height: 16px;
  width: 16px;
  border: 1px solid #d9d9d9;
  color: white;
  border-radius: 3px;
}

input[type="checkbox"]:after {
  content: " ";
  position: relative;
  left: 33%;
  top: 14%;
  width: 26%;
  height: 66%;
  outline: none;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
  border-radius: 3px;
}

input[type="checkbox"]:checked:after {
  display: block;
}

.alert-toast {
  height: auto;
  padding: 4px 4px 4px 4px;
  max-width: 500px;
  max-height: 200px;
  overflow-wrap: break-word;
}

.back-button:hover {
  content: url("/images/back-icon-hover.svg");
}

/* Scrollbar width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #c9c9c9;
  border: 2px solid #eeefee;
}

::-moz-scrollbar-thumb {
  border-radius: 6px;
  background-color: #c9c9c9;
  border: 2px solid #eeefee;
}

::-webkit-scrollbar-thumb:active {
  background-color: #b9b9b9;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #eeefee;
}

.private-scroll-x::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Scrollbar thumb */
.private-scroll-x::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

.private-scroll-x::-moz-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

/* Scrollbar track */
.private-scroll-x::-webkit-scrollbar-track {
  background-color: #edf2f7;
}

.private-scroll-x::-moz-scrollbar-track {
  background-color: #edf2f7;
}

.border-thin {
  border: 5px dashed #1A55F9;
  position: relative;
  border-radius: 12px;
}

.border-thin:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  border: 5px solid white;
  pointer-events: none;
  border-radius: 8px;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ced8e2;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgba(26, 85, 249, 1);

}

/* input:checked + .green {
  background-color: #4CAF50
} */

input:checked + .slider:before {
  transform: translateX(11px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-green {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ced8e2;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-green:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-green {
  background-color: #4caf50;
}

input:checked + .slider-green:before {
  transform: translateX(11px);
}

.slider-green.round {
  border-radius: 34px;
}

.slider-green.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .MuiTableCell-root.TableSelectAllCell-cell {
    text-align: left !important;
  }
  .css-1ybzgs6-MuiTableCell-root.TableSelectCell-cell {
    text-align: left !important;
  }
  .css-y0x3xi.TableSelectCell-cell {
    padding: 0px !important;
    text-align: left !important;
  }
  .css-zun73v {
    padding: 0px !important;
  }
}

.css-1ybzgs6-MuiTableCell-root .TableSelectCell-checkbox {
  padding: 0px !important;
}
.css-1ybzgs6-MuiTableCell-root {
  width: 10px !important;
  padding: 0px !important;
}

.webkit-smoothing {
  -webkit-font-smoothing: antialiased;
}

.markdown p {
  white-space: pre-wrap;
  font-size: 14px;
  margin: 5px 0;
}

.markdown table th,
.markdown table td {
  border: 1px solid black;
  padding: 8px;
}

.markdown table th {
  text-align: left;
}

.markdown a {
  color: #1A55F9;
}

.truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fit-width {
  width: fit-content !important;
}

.markdown ul {
  list-style: circle;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 14px;
}

.markdown ol {
  list-style: decimal;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 14px;
}

#basic {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #ced8e2;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}
#basic:checked {
  background-color: #1A55F9;
}
#advance {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #ced8e2;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}
#advance:checked {
  background-color: #1A55F9;
}

.select-active {
  background-image: url("/images/dropup-arrow.svg");
  cursor: pointer;
}

.select-inactive {
  background-image: url("/images/dropdown-arrow.svg");
  cursor: pointer;
}

.styled-select-category {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-style: normal;
  color: #949cae;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 95% center;
}

.css-173nqtf .ResizingControl-resizeHandleLine {
  background-color: #dddfde !important;
  opacity: 1 !important;
}
.css-pye9lr .CellLayout-resizeHandleLine {
  background-color: #dddfde !important;
  opacity: 1 !important;
}
@media (pointer: fine) {
  .css-1o6p3b3-MuiTableCell-root .CellLayout-resizeHandleLine {
    opacity: 1 !important;
  }
}

@media (pointer: fine) {
  .css-pye9lr .CellLayout-resizeHandleLine {
    opacity: 1 !important;
  }
}

.z-299 {
  z-index: 299;
}

.css-173nqtf.ResizingControl-resizeHandleSecondLine {
  opacity: 0 !important;
}

.z-999 {
  z-index: 999;
}

/**
 * Markdown Editor Styles
 */

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: auto;
  border-radius: 2px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}
.editor-test-container {
  /* margin: ; */
  flex: auto;
  justify-content: flex-start;
  border-radius: 2px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
}

.editor-input {
  min-height: 64px;
  max-height: 300px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  font-family: "Inter", Helvetica, Arial, Lucida, sans-serif;
  outline: 0;
  padding: 20px 18px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.editor-test-input {
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  font-family: "Inter", Helvetica, Arial, Lucida, sans-serif;
  outline: 0;
  padding-right: 20px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.editor-placeholder {
  color: #d9d9d9;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 20px;
  left: 20px;
  font-size: 18px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}
.editor-test-placeholder {
  color: #d9d9d9;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  bottom: 5px;
  left: 5px;
  font-size: 18px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
  font-family: "Inter", sans-serif;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  list-style-type: decimal;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  list-style-type: disc;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

.react-datepicker__close-icon::after {
  content: "X" !important;
  /* background-color: '#fff' !important; */
  /* background-size: contain !important;
  background-position: center !important; */
  padding: 1px;
}

.pink-gradient {
  background: rgb(255, 251, 255);
  background: linear-gradient(
    45deg,
    rgba(255, 254, 255, 0.956) 45%,
    rgba(253, 244, 252, 0.95) 48%
  );
}

.green-gradient {
  background: rgb(216, 245, 247);
  background: linear-gradient(
    45deg,
    rgba(232, 251, 252, 0.956) 45%,
    rgba(219, 251, 255, 0.95) 48%
  );
}

table.MuiTable-root.Table-table.css-1ecsaki-MuiTable-root {
  min-width: 0px !important;
}

.max-w-558px {
  width: 558px !important;
  min-width: 558px !important;
}
.min-w-100px {
  min-width: 100px !important;
}
.min-max-w-112px {
  min-width: 112px !important;
  max-width: 112px !important;
}
.min-h-100vh {
  min-height: 100vh !important;
}
.min-max-w-400px {
  min-width: 400px !important;
  max-width: 400px !important;
  width: 400px !important;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
}

.tooltip-container {
  position: relative;
}
.custom-scrollbar-hide {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.z-9999999 {
  z-index: 99999999 !important;
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.custom-scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

/* Loader */
.loader-5 {
  height: 40px;
  width: 40px;
  -webkit-animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-5-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-5::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 12px;
  height: 12px;
  background: #1A55F9;
  border-radius: 50%;
  -webkit-animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(24px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-5-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(24px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-5::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background: #1A55F9;
  border-radius: 50%;
  -webkit-animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-24px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-5-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-24px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-5 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 40px;
}
.loader-5 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background: #1A55F9;
  border-radius: 50%;
  -webkit-animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 24px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-5-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 24px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader-5 span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background: #1A55F9;
  border-radius: 50%;
  -webkit-animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -24px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-5-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.delete-icon:hover img {
  content: url("/images/red-delete.svg");
}

.MuiTableCell-root .TableSelectAllCell-checkbox {
  padding: 0px !important;
}

.MuiTableCell-root.TableSelectAllCell-cell {
  padding-left: 0px !important;
}

.MuiTableCell-root.TableSelectCell-cell {
  padding-left: 0px !important;
}

ul.slick-dots {
  text-align: left !important;
}

ul.slick-dots li {
  margin: 0px !important;
}

.custom-dots-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  ul.slick-dots {
    padding: 0px 80px !important;
    text-align: left !important;
  }

  .custom-dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  ul.slick-dots li {
    margin: 0px !important;
  }
}

.truncate-line-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.is-table-height {
  height: calc(100% - 210px);
}
.react-grid-Grid {
  min-height: 320px !important;
}

.delete-red-icon:hover {
  content: url("/images/delete-red-icon.svg");
}
.pg-viewer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.pg-viewer-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

.pg-viewer-wrapper .pg-viewer .loading-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pg-viewer-wrapper .pg-viewer .loading-container .loading {
  background-image: none;
  width: 40px;
  height: 40px;
  border: 3px solid #1A55F9;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
canvas {
  width: 100% !important;

}

.markdown table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.markdown th,
.markdown td {
  border: 1px solid #b9b9b9; /* Solid border for each cell */
  padding: 8px 12px; /* Add padding for spacing */
  text-align: left;
  white-space: nowrap;
}

.markdown th {
  background-color: #f5f5f5; /* Light gray background for headers */
  font-weight: 600;
  min-width: 165px; /* Minimum width for better readability */
}

.markdown td {
  background-color: #fff; /* White background for table rows */
}

/* Optional: For visual consistency */
.markdown tr:last-child td {
  border-bottom: 1px solid #000; /* Ensure bottom row has a border */
}
.circle {
  width: 21px;
  height: 21px;
  background-color: #e2e4ff;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.container {
  height: calc(100vh - 100px);
  overflow: hidden;
  width: 100%;
}

.markdown th,
.markdown td {
  border: 1px solid #b9b9b9; /* Solid border for each cell */
  padding: 8px 12px; /* Add padding for spacing */
  text-align: left;
  white-space: nowrap;
}

.markdown th {
  background-color: #f5f5f5; /* Light gray background for headers */
  font-weight: 600;
  min-width: 165px; /* Minimum width for better readability */
}

.markdown td {
  background-color: #fff; /* White background for table rows */
}

/* Optional: For visual consistency */
.markdown tr:last-child td {
  border-bottom: 1px solid #000; /* Ensure bottom row has a border */
}
.circle {
  width: 21px;
  height: 21px;
  background-color: #e2e4ff;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.container {
  height: calc(100vh - 100px);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .scrollable-container {
    height: 90%;
    max-height: 90%;
  }
}

.scrollable-container {
  max-height: 100%;
  overflow-y: auto;
  padding: 16px;
  width: 90vw;
}

.w-full {
  width: 100%;
}

.adjusted-height {
  height: calc(100vh - 100px);
}
.builder-sidebar-height {
  height: calc(100vh - 50px);
}
.adjusted-height-screen {
  height: calc(100vh - 200px);
}

.fullscreen-height {
  height: 100vh;
}

.adjusted-full-height {
  height: calc(100% - 50px);
}

.h-calc {
  height: calc(50% - 10px);
}

.shadow-query {
  box-shadow: 0px 4px 7px 0px rgba(32, 32, 32, 0.2);
}
.no-hover:hover {
  pointer-events: none;
  cursor: default;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 1080px) {
  .screen-fit {
    height: calc(100vh - 150px);
  }
}
.react-datepicker__header {
  background-color: white !important;
}

span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-checked.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.TableSelectCell-checkbox.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  color: #1A55F9 !important;
 
}
 
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-checked.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.TableSelectAllCell-checkbox.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  color: #1A55F9 !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #1A55F9 !important;
}

.flow-background {
  background: linear-gradient(90deg, #f4f5fc 10px, transparent 70%) center,
    linear-gradient(#f4f5fc 10px, transparent 70%) center,
    rgba(224, 228, 232, 0.7);
  background-size: 12px 12px;
}

span.Resizer.vertical {
  cursor: ew-resize;
  width: 16px;
  background: white;
}

span.Resizer.horizontal {
  cursor: ns-resize;
  width: 100%;
  background: white;
  height: 10px;
}

/* Material-UI Checkbox Styles with increased specificity */
.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
span.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
span.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox.Mui-checked,
.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox.Mui-checked,
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-checked,
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-checked,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked,
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked,
.TableSelectCell-checkbox.Mui-checked,
.TableSelectAllCell-checkbox.Mui-checked,
.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked,
.PrivateSwitchBase-root.MuiCheckbox-root.Mui-checked,
span.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox.Mui-checked,
.MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
[class*="MuiCheckbox-root"][class*="Mui-checked"],
[class*="PrivateSwitchBase-root"][class*="Mui-checked"] {
  color: #1A55F9 !important;
}

/* Ensure styles apply to both table select and regular checkboxes */
.MuiTableCell-root .TableSelectCell-checkbox.Mui-checked,
.MuiTableCell-root .TableSelectAllCell-checkbox.Mui-checked,
.MuiTableCell-root [class*="MuiCheckbox"][class*="Mui-checked"] {
  color: #1A55F9 !important;
}

/* Handle any dynamic class names Material-UI might generate */
[class*="MuiCheckbox"][class*="Mui-checked"],
[class*="MuiButtonBase"][class*="MuiCheckbox"][class*="Mui-checked"],
[class*="PrivateSwitchBase"][class*="MuiCheckbox"][class*="Mui-checked"] {
  color: #1A55F9 !important;
}

/* Override any default Material-UI styles */
.MuiCheckbox-root.Mui-checked svg,
.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #1A55F9 !important;
}

code{
  white-space: pre-wrap;
  word-break: break-word;
}

.custom-slider .MuiSlider-rail {
  background-color: #E4E4E4; 
}

.custom-slider .MuiSlider-track {
  background-color: #1A55F9; 
}

.custom-slider .MuiSlider-thumb {
  background-color: #FFFFFF;
}

.custom-slider .MuiSlider-thumb:hover {
  border: 2px solid #1A55F9;
  box-shadow: none;
}

.Ecg7PG_spectrum-Dialog-grid {
  display: block !important;
  padding: 18px;
}
.responsive-height {
  height: 240px; 
}

.custom-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
@media screen and (min-width: 1800px) {
  .responsive-height {
    height: 320px; 
  }
}
.custom-row:nth-child(even) {
  background-color: #F5F7FC;
}

.custom-row:nth-child(even):hover {
  background-color: #F2F5FC;
}

.custom-row:nth-child(odd) {
  background-color: #FFFFFF;
}

.custom-row:nth-child(odd):hover {
  background-color: #FDFDFF;
}
input[type="text"]:focus,
textarea:focus {
  border-color: #1A55F9 !important;
  outline: none; 
}

.custom-width{
  max-width: 100%;
  overflow-x: auto;
  word-break: break-word;
}
